<template>
  <div class="customize">
    <LSHeader :navigateTo="goBack" :url="goBackUrl"/>
    
    <main>
      <h1 class="customize__title">CUSTOMIZE YOUR LIQUID SOUND</h1>

      <div class="customize__content">
        <div class="customize__options-card">
          <BottleOptionsCard
          @update:selectedCombination="handleSelection"
        />
        </div>

        <div class="customize__bottle">
          <img :src="currentBottle" alt="Bottle" />
          <div class="">
            <span class="customize__bottle-text span-fade-in" :style="{ color: nameColor }">{{
              bottleTitle
            }}</span>
          </div>
          <img class="customize__bottle-logo" :src="logo" alt="liquid sound logo" />
        </div>
        
        <div class="customize__color-card">
          <TitleAndColorCard
            @handleColorChange="handleColorChange"
            @handleTitleChange="handleTitleChange"
          />
        </div>
        
        <div class="customize__purchase-btn">
          <MainButton  @click="goToThankYou">
            <span class="span-fade-in">PURCHASE</span>
            <span class="span-fade-in">&nbsp;NOW</span>
        </MainButton>
        </div>
      </div>
    </main>

  </div>
</template>

<script setup>
import BottleOptionsCard from '../components/BottleOptionsCard.vue';
import TitleAndColorCard from '../components/TitleAndColorCard.vue';
import butla from '../assets/images/bottleVariants/whiteSilver.png';
import logo from '../assets/images/logox1.png';
import MainButton from '../components/MainButton.vue';
import LSHeader from '@/components/LSHeader.vue';

import { ref } from 'vue';
import { useRouter } from 'vue-router';

const bottleTitle = ref('Name Your Fragrance');
const nameColor = ref('#000');
const router = useRouter();
const currentBottle = ref(butla);
const handleTitleChange = newTitle => {
  bottleTitle.value = newTitle;
};

const goBackUrl = '/scent-breakdown'

function handleSelection(path) {
  console.log('Selected Bottle:', path);
  currentBottle.value = path;
}

function goToThankYou() {
  router.push('/thank-you');
}

const handleColorChange = newColor => {
  nameColor.value = newColor;
};

function goBack() {
  router.push(goBackUrl);
}
</script>

<style scoped>

main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.customize {
  text-align: center;
  padding: 10px 24px;
  max-width: 70%;
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.customize__options-card, .customize__color-card {
  max-width: 300px;
  animation: show-in 1s;
}

#bgVideo {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  min-height: 100vh;
  transform: translate(-50%, -50%);
  z-index: -1;
  object-fit: cover;
  opacity: 0.3 !important;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header-icon {
  font-size: 24px;
}

.customize__title {
  font-size: 36px;
  letter-spacing: 0.05em;
  margin: auto auto 40px auto;
  text-wrap: nowrap;
  color: var(--title-text-color);
}

.customize__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: auto;
  gap: 5%;
}

.customize__content > * {
  flex: 1;
}

.customize__bottle img {
  width: 100%;
  height: auto;
  animation: resize-width 2s;
}

.customize__bottle {
  max-width: 375px;
  position: relative;
  animation: slide-in 2s;
}

.customize__bottle-text {
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  font-family: OutfitLight, sans-serif;
  font-weight: 100;
  font-size: 20px;
  word-break: break-word;
}

img.customize__bottle-logo {
  position: absolute;
  bottom: 45%;
  left: 50%;
  width: 70% !important;
  mix-blend-mode: multiply;
  transform: translate(-50%, 50%);
  animation: slide-in-logo 2s;
}

.customize__bottle-text span {
  font-size: 18px;
}

.bottle-image-textUp {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
  font-family: OutfitLight, sans-serif;
  font-weight: 600;
  font-size: 24px;
  word-break: break-word;
}

.bottle-image-textUp span {
  font-family: 'OutfitRegular', sans-serif;
  font-weight: 400;
}

@keyframes slide-in-logo {
  0% {
    opacity: 0;
    width: 0%;
  }

  50% {
    opacity: 0;
    width: 0%;
  }

  100% {
    width: 20%;
    opacity: 1;
  }
}

@keyframes resize-width {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes slide-in {
  0% {
    transform: translateY(150%);
    opacity: 0;
    width: 0%;
  }

  50% {
    opacity: 0;
    width: 0%;
  }

  100% {
    transform: translateY(0%);
    width: 100%;
    opacity: 1;
  }
}

span {
  display: inline-block;
  opacity: 0;
  filter: blur(4px);
}

span:nth-child(1) {
  animation: fade-in 0.8s 2.25s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(2) {
  animation: fade-in 0.8s 2.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(3) {
  animation: fade-in 0.8s 2.75s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(4) {
  animation: fade-in 0.8s 3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(5) {
  animation: fade-in 0.8s 3.25s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(6) {
  animation: fade-in 0.8s 3.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

@keyframes show-in {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.showIn {
  animation: show-in 1s;
}

.customize__purchase-btn {
  display: none;
}

.customize__color-card {
  width: 100%;
  max-width: 300px;
}

@media (max-width: 1200px) {
  @keyframes slide-in {
    0% {
      transform: translateY(150%);
      opacity: 0;
      width: 0%;
    }

    50% {
      opacity: 0;
      width: 0%;
    }

    100% {
      transform: translateY(0%);
      width: 50%;
      opacity: 1;
    }
  }

  .customize {
    height: auto;
    max-width: 100%;
  }

  .customize__content {
    flex-direction: column;
  }

  .customize__bottle {
    order: -1;
    width: 50%;
  }

  .customize__color-card {
    order: -1;
  }

  .customize__purchase-btn {
    display: block;
    margin: 20px auto;
    padding: 14px 36px;
  }

  .options-card {
    margin: 20px 0;
  }
}

@media (max-width: 992px) {
  @keyframes slide-in {
    0% {
      transform: translateY(150%);
      opacity: 0;
      width: 0%;
    }

    50% {
      opacity: 0;
      width: 0%;
    }

    100% {
      transform: translateY(0%);
      width: 70%;
      opacity: 1;
    }
  }

  .customize__title {
    font-size: 3.5vmax;
    margin: 30px 0;
  }

  .customize__bottle {
    margin: 20px 0;
    width: 70%;
  }

  .notes-section > * {
    width: 75%;
  }

  .customizeBtnWrapper {
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  @keyframes slide-in {
    0% {
      transform: translateY(150%);
      opacity: 0;
      width: 0%;
    }

    50% {
      opacity: 0;
      width: 0%;
    }

    100% {
      transform: translateY(0%);
      width: 80%;
      opacity: 1;
    }
  }

  .customize__bottle {
    margin: 20px 0;
    width: 80%;
  }

  .bottle-image-textUp {
    font-size: 22px;
  }

  .customize__bottle-text {
    font-size: 16px;
  }

  .customize__title {
    font-size: 1.9vmax;
    text-wrap: nowrap;
  }

  .customize__options-card, .customize__color-card {
    max-width: 300px;
  }
}
</style>
