<template>
  <div id="behind">
    <div class="nav">
      <LSHeader :navigate-to="goBack" :url="goBackUrl"/>
    </div>
    <div class="behind-container">
      <p>
        LiquidSound explores the question, “What does sound taste like?”
        Inspired by synesthesia, it pairs frequencies with fragrances to create
        personalized scents from ambient sound. Each note becomes an experience,
        transforming sound into something you can feel, carry, and remember.
      </p>
      <p>
        Pairing Sound with Perfume Notes <br />
        In Liquid Sound, we transform sounds into a sensory experience you can
        smell. How does this unique pairing of sounds and pefrume notes work?
      </p>
      <p>
        Vibration frequencies and scent <br />
        Every sound has a unique frequency. High-pitched sounds are bright and
        lively, while low ones are deep and rich. <br />
        Perfume notes work similarly: top notes (like citrus) are fresh and
        fleeting, while base notes (like amber or musk) are grounding. By
        matching these frequencies, we create harmony between sound and scent.
      </p>
      <p>
        Emotion and memory <br />
        Sound and scent both evoke strong emotions and memories. A lively sound
        might pair with citrus for energy, while a warm tone links to smoothing
        scents like vanilla. This pairing immerses you in a multi-sensory
        journey that brings each sound to life in scent form.
      </p>
      <p>
        Throught LiquidSound, sound isn’t just something you hear - it’s
        something you smell and feel, transforming each sound into an olfactory
        journey that invites you to explore a new harmony of sound and scent
      </p>
    </div>
  </div>
</template>

<script setup>
import Wave from '../components/Wave.vue';
import GoBack from '@/components/Navigator.vue';
import MainButton from '@/components/MainButton.vue';
import LSHeader from '@/components/LSHeader.vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const goBackUrl = '/main'

function goBack() {
  router.push(goBackUrl);
}

</script>

<style scoped>

.nav {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 12px;
  justify-content: flex-start;
  width: 100%;
}

.ls-question {
  margin: auto auto 15% auto;
  font-size: 18px;
}

.behindTitle-title {
  font-size: 92px;
  font-weight: 100;
  text-align: right;
}

.last {
  max-width: 65%;
  text-align: left;
}

.behindScience-article-title {
  text-align: left;
  margin: auto;
}

.dot {
  background: #06001c;
  height: 18px;
  width: 18px;
  min-width: 18px;
  max-width: 18px;
  border-radius: 50%;
}

.record-header {
  flex-shrink: 0;
}

.throughLiquidTitle {
  font-size: 61px;
  font-weight: 100;
  text-align: left;
}

.smell-like {
  font-size: 48px;
  font-weight: 100;
  text-align: left;
}

.wave {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.wave > * {
  margin: 0;
}

h1 {
  font-size: 2.5vmax;
}

h2 {
  font-size: 2vmax;
}

h1,
h2 {
  text-transform: uppercase;
  text-wrap: wrap;
}

h3,
h4,
h5,
h3 {
  letter-spacing: 0;
  margin: 0;
}

#behind {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  max-width: 100%;
  margin: auto;
  padding: 0 24px;
  height: 100%;
  min-height: fit-content;
}

.behind-container {
  max-width: 66ch;
  text-align: left;
  margin: auto;
  color: var(--text-color);
}

.behindScience-summary {
  padding-top: 2%;
}

.behindScience {
  max-width: 100%;
  margin: 0 auto 3%;
}

.behindScience-title {
  margin: 0 0 3%;
  text-align: left;
}

.behindTitle-container {
  display: flex;
  max-width: 100%;
  margin: 0 auto 5%;
  gap: 5%;
}

.behindTitle-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.behindScience-article {
  border-top: 1px gray solid;
  padding: 20px;
  display: flex;
  flex: 0;
}

.behindScience-article:last-of-type {
  border-bottom: 1px gray solid;
}

.behindScience-article h3 {
  text-transform: uppercase;
  font-size: 20px;
  width: 300px;
  flex-basis: 30%;
}

.behindScience-article-text {
  margin: 0 auto 2%;
  max-width: 50%;
  text-align: left;
  flex-basis: 50%;
}

.behindTitle-text {
  max-width: 50%;
  margin: auto;
}

.nav {
  display: flex;
  width: 100%;
  margin: auto unset;
}

p,
h3 {
  font-size: 1.1vmax;
}

@media (max-width: 992px) {
  .ls-question {
    margin: 15% auto 0 auto;
    font-size: 18px;
  }

  .behindTitle-title {
    font-size: 50px;
    text-align: center;
  }

  .throughLiquidTitle {
    font-size: 40px;
    font-weight: 100;
    text-align: center;
  }

  .smell-like {
    font-size: 48px;
    font-weight: 100;
    text-align: center;
  }

  .last {
    max-width: 65%;
    text-align: center;
    font-size: 16px;
  }

  .dot {
    background: #06001c;
    height: 12px;
    width: 12px;
    min-width: 12px;
    max-width: 12px;
    border-radius: 50%;
    flex-basis: 20%;
  }

  .behindTitle-container {
    flex-direction: column;
  }

  .behindTitle-content {
    width: 100%;
  }

  h2 {
    font-size: 1.9vmax;
  }

  h1,
  h2 {
    letter-spacing: 0;
  }

  p,
  h3 {
    font-size: 2vmax;
  }

  .behind-container {
  }

  .behindScience-article-text,
  .behindTitle-text {
    max-width: 80%;
  }

  .behindScience-summary {
    margin-top: 8%;
  }

  .behindScience-article h3 {
    font-size: 12px;
  }
}
</style>
