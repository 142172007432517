<template>
  <button
    :class="`${variant}-button ${getFullWidthModifier()}`"
    @mouseleave="active = false"
  >
    <slot class="content" />
  </button>
</template>

<script setup>
import { ref } from 'vue';
const active = ref(false);

const { variant, fullWidth } = defineProps({
  variant: {
    required: false,
    default: 'solid',
    type: String,
  },
  fullWidth: {
    required: false,
    default: false,
    type: Boolean,
  },
})

const getFullWidthModifier = () => {
  const fullWidthModifier = `${variant}-button--full-width`
  return fullWidth ? fullWidthModifier : ''
}

</script>

<style scoped>
.outline-button, .solid-button {
  --btn-border-color: white;
    
  --solid-btn-bg-color: white;
  --solid-btn-text-color: #06001C;
  --solid-btn-text-hover-color: white;
  --solid-btn-text-inverted-color: #06001C;
  
  --outline-btn-hover-fill: white;
  --outline-btn-text-hover-color: #06001C;
  --outline-btn-text-color: white;
}

.outline-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 22px 44px;
  background-color: transparent;
  border-radius: 50px;
  border: 2px solid var(--btn-border-color);
  font-size: 18px;
  cursor: pointer;
  overflow: hidden;
  width: auto;
  height: 48px;
  transition: color 0.3s ease, transform 0.3s ease;
  animation: resize 1s;
  text-transform: uppercase;
  text-wrap: nowrap;
  color: var(--outline-btn-text-color);
}

.outline-button:hover {
  color: var(--outline-btn-text-hover-color) !important;
}

.outline-button * {
  z-index: 1;
}

.outline-button::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  background-color: var(--outline-btn-hover-fill);
  border-radius: 50%;
  transition: width 0.5s ease, height 0.5s ease, top 0.5s ease, left 0.5s ease;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.outline-button:hover *  {
  color: var(--outline-btn-text-hover-color);
}

.outline-button:hover::before {
  width: 300%;
  height: 300%;
  top: 50%;
  left: 50%;
}

.outline-button.active {
  transform: scale(1.1);
  height: 72px;
}

.solid-button {
  position: relative;
  padding: 25px 44px;
  background-color: var(--solid-btn-bg-color);
  color: var(--solid-btn-txt-color);
  border-radius: 50px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  overflow: hidden;
  width: auto;
  height: 48px;
  transition: color 0.3s ease, transform 0.3s ease;
  z-index: 999;
  animation: resize 1.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  text-wrap: nowrap;
}

.solid-button * {
  color: var(--solid-btn-text-color);
}

.solid-button:hover *  {
  color: var(--solid-btn-text-hover-color);
}

.content {
  position: absolute;
  z-index: 2;
}

.solid-button::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  background: var(--solid-btn-text-inverted-color);
  border-radius: 50%;
  transition: width 0.5s ease, height 0.5s ease, top 0.5s ease, left 0.5s ease;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.solid-button:hover::before {
  width: 300%;
  height: 300%;
  top: 50%;
  left: 50%;
}

.solid-button:hover {
  color: var(--solid-btn-text-hover-color);
}

.solid-button.active {
  transform: scale(1.1);
  height: 72px;
}


.solid-button--full-width,
.outline-button--full-width {
  width: 100%;
}

@keyframes resize {
  0% {
    scale: 1.2;
  }
  100% {
    scale: 1;
  }
}
</style>
