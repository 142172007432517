<template>
  <div class="product">

    <LSHeader :navCallback="stopSound" :navigate-to="goBack" :url="goBackUrl"/>

    <h1 class="product__title">
      <span class="span-fade-in">{{ name }}</span>
    </h1>

    <main class="product__main">
      <div class="product__note-description">
        <NoteDisplay
          :musicNotes="musicNotes"
          :topNotes="topNotes"
          :middleNotes="middleNotes"
        />
        <div class="product__desktop-sound-button">
          <PlaySoundBtn @click="playSound" />
        </div>
      </div>

      <img class="product__bottle" :src="movie || butla" alt="Perfume bottle">

      <div class="product__perfume-description">
        <DetailsDisplay
          :edition="edition"
          :quantity="quantity"
          :size="size"
        />
        <div class="product__desktop-payment-button">
          <PaymentBtn :price="price" />
        </div>
      </div>

      <div class="product__mobile-sound-button">
        <PlaySoundBtn @click="playSound"  />
      </div>

      <div class="product__mobile-payment-button">
        <PaymentBtn  :price="price" />
      </div>
    </main>

  </div>
</template>

<script setup>
import { useBottleStore } from '../store/bottleStore';
import { useRouter } from 'vue-router';
import NoteDisplay from '../components/NotesCard.vue';
import DetailsDisplay from '../components/DetailsCard.vue';
import butla from '/public/movies/white.png';
import PlaySoundBtn from '@/components/PlaySoundBtn.vue';
import PaymentBtn from '@/components/PaymentBtn.vue';
import jung from '../assets/songs/jung.mp3';
import LSHeader from '@/components/LSHeader.vue';

const bottleStore = useBottleStore();

const defaultData = {
  name: 'Concrete Jungle!',
  topNotes: ['Chestnut', 'Green Mandarin'],
  middleNotes: ['Hazelnut', 'Chocolate'],
  musicNotes: ['D4', 'F4', 'C5'],
  price: 130,
  edition: '30/50',
  size: '50mm/1.7 fl oz',
  quantity: '01',
  song: jung,
};

const {
  name,
  topNotes,
  middleNotes,
  musicNotes,
  price,
  edition,
  size,
  quantity,
  song,
  movie,
} = bottleStore.getBottleData ?? defaultData;
const router = useRouter();
const audio = new Audio(song ?? atchoo);

function playSound() {
  audio.play();
}

function stopSound() {
  audio.pause();
}

const goBackUrl = '/playlist'

function goBack() {
  router.push(goBackUrl);
}
</script>

<style scoped>
.product {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 95%;
  max-width: 70%;
  margin: auto;
  padding: 24px;
}

.product__desktop-sound-button,
.product__desktop-payment-button {
  width: 100%;
}

.soundBtn {
  margin-top: 20px;
  display: flex;
  text-wrap: nowrap;
  max-width: 50%;
}

.soundWave {
  display: inline;
  margin: 3px 0 0 0;
  width: 50%;
  animation: show-in 10s;
  opacity: 0;
}

.soundWave > img {
  animation: show-in 10s;
}

#bgVideo {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  min-height: 100vh;
  transform: translate(-50%, -50%);
  z-index: -1;
  object-fit: cover;
  opacity: 0.4 !important;
}

.product-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  margin-bottom: auto;
}

.header-icon {
  font-size: 24px;
}

.product__main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5%;
  width: 100%;
  margin: 0 auto auto auto;
  flex-wrap: wrap;
}

.product__main > * {
  flex: 1;
}

.product__note-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  width: 100%;
  animation: show-content 2s;
}

.product__bottle {
  height: auto;
  animation: fade-out 2s;
  width: 70%;
  max-width: 300px;
  margin-bottom: 80px;
  min-width: 150px;
}

.product__perfume-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  width: 100%;
  animation: show-content 2s;
}

@keyframes res {
  0% {
    scale: 200%;
  }
  100% {
    scale: 100%;
  }
}

.sound-icon {
  margin-right: 10px;
}

.checkout-btn {
  margin-top: 20px;
  padding: 10px 30px;
  background-color: #06001c;
  color: #ffffff;
  border-radius: 50px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  letter-spacing: 0.05em;
}

.product__title {
  font-weight: 600;
  letter-spacing: 0.05em;
  
  margin: auto;
  font-size: 64px;
  margin-bottom: 5%;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: var(--title-text-color);
}

.bottle-0 {
  animation: fade-out 1s;
}
.product__mobile-payment-button,
.product__mobile-sound-button {
  display: none;
  width: 100%;
}

@keyframes fade-out {
  0% {
    opacity: 0;
    transform: translateY(15%);
  }
  10% {
    opacity: 0.05;
  }
  20% {
    opacity: 0.05;
  }
  30% {
    opacity: 0.1;
  }
  40% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes show-in {
  0% {
    opacity: 0 !important;
    mix-blend-mode: multiply;
  }
  50% {
    opacity: 0 !important;
    mix-blend-mode: multiply;
  }
  100% {
    opacity: 1;
    mix-blend-mode: multiply;
  }
}

@keyframes show-content {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}

@media (max-width: 1200px) {
  .product {
    height: auto;
  }

  .product__title {
    margin: 60px 0;
  }
}

@media (max-width: 992px) {
  .product__bottle {
    order: -1;
  }

  .product__perfume-description,
  .product__note-description {
    margin: 0 0 20px;
  }

  .product__title {
    font-size: 36px;
  }

  .product__main {
    flex-direction: column;
    max-width: 70%;
  }

  .product__desktop-sound-button, 
  .product__desktop-payment-button {
    display: none;
  }

  .product__mobile-payment-button,
  .product__mobile-sound-button {
    display: flex;
    max-width: 350px;
  }

  .soundWave {
    width: 10%;
  }
}

@media (max-width: 576px) {
  .product {
    max-width: 100%;
  }
}
</style>
