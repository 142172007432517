<template>
  <div class="breakdown">
    <LSHeader :navigate-to="goBack" :url="goBackUrl"/>

    <h1 class="breakdown__title">BREAKDOWN OF SCENTS</h1>

    <main class="breakdown__main">
      <div class="breakdown__notes-card">
        <NotesCard
          :musicNotes="musicNotes"
          :topNotes="topNotes"
          :middleNotes="middleNotes"
        />
      </div>

      <figure class="breakdown__bottle">
        <img class="breakdown__rotating-bottle" :src="butla" alt="Animated rotating bottle" >
      </figure>
      
      <div class="breakdown__customize">
        <MainButton class="breakdown__customize-btn" @click="goToCustomize">
            <span class="span-fade-in">CUSTOMIZE</span> <span class="span-fade-in">&nbsp;BOTTLE</span>
        </MainButton>
      </div>
    </main>
  </div>
</template>

<script setup>
import NotesCard from '../components/NotesCard.vue';
import MainButton from '../components/MainButton.vue';
import butla from '/public/movies/white.png';
import LSHeader from '@/components/LSHeader.vue';

import { useRouter } from 'vue-router';

const defaultData = {  name: 'Atchoo!',
  topNotes: ['Chestnut', 'Green Mandarin'],
  middleNotes: ['Hazelnut', 'Chocolate'],
  musicNotes: ['D4', 'F4', 'C5'],
  price: 130,
  edition: '30/50',
  size: '50mm/1.7 fl oz',
  quantity: '01'} 

const {
  name,
  topNotes,
  middleNotes,
  musicNotes,
  price,
  edition,
  size,
  quantity,
} = window.bottle ?? defaultData;

const router = useRouter();
function goToCustomize() {
  router.push('/customize');
}

const goBackUrl = '/analyze'

function goBack() {
  router.push(goBackUrl);
}
</script>

<style scoped>
.breakdown {
  text-align: center;
  padding: 24px;
  max-width: 70%;
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 95%;
}

.breakdown__notes-card {
  display: flex;
  width: 100%;
}

#bgVideo {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  min-height: 100vh;
  transform: translate(-50%, -50%);
  z-index: -1;
  object-fit: cover;
  opacity: 0.3 !important;
}

.breakdown__rotating-bottle {
  width: 100%;
  animation: fade-out 2s;
}

.breakdown__customize-btn {
  text-wrap: nowrap;
  margin: auto;
}

.breakdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.breakdown-nav {
  display: flex;
}

.header-icon {
  font-size: 24px;
}

.breakdown__title {
  font-size: 3.1vmax;
  margin: auto auto 0 auto;
  text-wrap: nowrap;
  color: var(--title-text-color);
}

.breakdown__main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto 0;
}

.breakdown__main > * {
  flex: 1;
}

.breakdown__bottle img {
  width: 300px;
  height: auto;
}

.customize-button {
  width: 30%;
  display: flex;
  justify-content: center;
}

.btn-customize {
  padding: 10px 20px;
  background-color: #06001c;
  color: #ffffff;
  border-radius: 50px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  letter-spacing: 0.05em;
}

@keyframes fade-out {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  10% {
    opacity: 0.05;
  }
  20% {
    opacity: 0.05;
  }
  30% {
    opacity: 0.1;
  }
  40% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes show-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 1700px) {
  .breakdown__rotating-bottle {
    width: 80%
  }
}

@media (max-width: 1500px) {
  .breakdown__title {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .breakdown__rotating-bottle {
    width: 60%
  }
}

@media (max-width: 1200px) {
  .breakdown__title {
    font-size: 4.3vmax;
  }

  .breakdown__main {
    flex-direction: column;
  }

  .breakdown__notes-card {
    max-width: 350px;
    margin: 20px 0;
  }

  .breakdown {
    max-width: 100%;
  }

  .breakdown__bottle {
    margin: 20px 0;
    order: -1;
  }
}

@media (max-width: 992px) {
  .breakdown__title {
    font-size: 1.6vmax;
  }

  .breakdown__customize {
    margin-bottom: 20px;
  }
}
</style>
